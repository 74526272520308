<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field hide-details="" label="Año Estudio" class="my-3" prepend-icon="mdi-calendar" filled dense v-model="year"></v-text-field>
        <v-file-input hide-details="" label="Importar" filled dense v-model="newEstudio"></v-file-input>
        <!-- <template v-if="!saveMethod">
          <AuxInput class="mx-2 no-gutters" title="Ventas">
            <v-text-field filled v-model="newEstudio['40100']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="Aprovisionamientos">
            <v-text-field filled v-model="newEstudio['40400']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="Otros Ingresos Expl.">
            <v-text-field filled v-model="newEstudio['40500']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="Gastos Personal">
            <v-text-field filled v-model="newEstudio['40600']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="O.C.E. y GG">
            <v-text-field filled v-model="newEstudio['40700']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="Imputación Subv. Inm No financ.">
            <v-text-field filled v-model="newEstudio['40900']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="Otros Resultados / Resultados Extraord.">
            <v-text-field filled v-model="newEstudio['41300']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>

          <AuxInput class="mx-2 no-gutters" title="Amortización">
            <v-text-field filled v-model="newEstudio['40800']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="Resultado Financiero">
            <v-text-field filled v-model="newEstudio['49200']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="IS Sociedades">
            <v-text-field filled v-model="newEstudio['41900']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <v-divider class="my-2"></v-divider>
          <AuxInput class="mx-2 no-gutters" title="Efectivo (y otros act. líquidos)">
            <v-text-field filled v-model="newEstudio['40700']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="">
            <v-text-field filled v-model="newEstudio['40700']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="">
            <v-text-field filled v-model="newEstudio['40700']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
          <AuxInput class="mx-2 no-gutters" title="">
            <v-text-field filled v-model="newEstudio['40700']" dense hide-details
              class="v-superdense-input"></v-text-field>
          </AuxInput>
        </template> -->
      </v-card-text>
      <v-card-actions class="pt-0" >
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" :disabled="!year || newEstudio == null" text @click.native="save">Guardar</v-btn>
        <!-- <v-btn color="info" text @click.native="saveMethod = !saveMethod">Manual</v-btn> -->
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  data: () => ({
    year: new Date().getFullYear()-1,
    // saveMethod: true,
    newEstudio: null,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message) {
      this.dialog = true
      this.title = title
      this.message = message
      // this.saveMethod = saveMethod
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    save() {
      this.resolve({ year: this.year, newEstudio: this.newEstudio })
      this.newEstudio = null
      this.year = new Date().getFullYear()-1
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.newEstudio = null
      this.year = new Date().getFullYear()-1
      this.dialog = false
    }
  }
}
</script>